import React, { useState } from "react";
//= Layout
import MainLayout from "layouts/Main";
//= Components
import Navbar from "components/Navbars/ITCreativeNav";
import ChatBanner from "components/Software/ChatBanner";
import Footer from "components/Saas/Footer";
import Blog7 from "components/Software/Blog7";
import { Link } from "gatsby";

const TreasuryNote = () => {
  const [isOpen, setOpen] = useState(false);

  const openVideo = (e) => {
    e.preventDefault();
    setOpen(true);
  };
  return (
    <MainLayout>
      <Navbar />
      <header
        className="style-3 overflow-hidden"
        data-scroll-index="0"
        style={{ background: "#198754" }}
      >
        <div className="container">
          <div className="content section-padding">
            <div className="row">
              <div className="col-lg-5">
                <div className="info" style={{ background: '#00000000'  }}>
                  <h1 className="h1">
                    Secure Your Future with <span> Treasury Notes</span>
                  </h1>
                  <p className="p">
                    Invest in stability with our high-yield Treasury Notes
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-img ">
          <img src="/assets/img/about/style_3_1.png" style={{ maxWidth: '30%'}} alt="" />
          <img
            src="/assets/img/about/TREASURY NOTE.png"
            alt=""
            className="img-body "
          />
        </div>
      </header>
      <main>
        <section
          className="about section-padding style-3"
          data-scroll-index="3"
        >
          <div className="container">
            <div className="row gx-0 justify-content-end">
              <div className="col-lg-6">
                <div className="img  ">
                  <img
                    src="/assets/img/about/TREASURY NOTE.png"
                    alt=""
                    className="img-body "
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="">
                  <div className="section-head long-shape mb-40 style-3">
                    <h3>Treasury Note </h3>
                  </div>
                  <p>
                    Achieve financial security with our Treasury Notes, offering
                    you a stable and lucrative investment option with guaranteed
                    returns. Our Treasury Notes provide an opportunity to grow
                    your wealth with peace of mind, offering you secure,
                    government-backed investments with attractive yields and
                    flexible terms. Whether you're building long-term savings or
                    looking to secure your legacy, our Treasury Notes are
                    tailored to meet your needs with simplicity and
                    transparency.
                  </p>
                  <div className="text mb-20 mt-20">
                    <h5>Requirements: </h5>
                    <ul>
                      <li>
                        Valid Identification (National ID, Driver’s License, or
                        Passport)
                      </li>
                      <li>Bank Verification Number (BVN)</li>
                      <li>Completed KYC verification</li>
                      <li>Proof of income or investment source</li>
                    </ul>
                  </div>
                  <Link
                    to="/contact"
                    className="btn rounded-pill bg-blue2 sm-butn text-white mt-10"
                  >
                    <span>Invest in Treasury Notes</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Blog7 />
        <ChatBanner />
      </main>
      <Footer />
    </MainLayout>
  );
};

export const Head = () => {
  return (
    <>
     <title> Enjoy A Safe and Guaranteed ROI on your Funds with Us</title>
<meta name="description" content="Our Treasury Notes offer a stable and profitable investment opportunity. We offer a safe and guaranteed Return on your Investments. Grow your funds with us" /> 

      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />
    </>
  );
};

export default TreasuryNote;
